import React from "react";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { ContentWrapper, Table } from "components";
import { Col, Input, notification, Row, Select } from "antd";
import { DateTime } from "luxon";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";

import "./styles.scss";

class OrderRoute extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			defaultVans: [],
			order: null,
			hasUnsavedFields: false
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch, match } = this.props;
		const {
			params: { id }
		} = match || {};

		dispatch(setLoader(true));

		try {
			const [response, responseVans] = await Promise.all([
				API.get({
					url: Endpoints.uriOrders(id)
				}),
				API.get({
					url: Endpoints.uriVehicles()
				})
			]);

			if (response.ok && responseVans.ok) {
				const { order } = response.data.results || {};
				const { vans: defaultVans = [] } = responseVans.data.results || {};

				dispatch(setTitle(order.orderFlow?.route?.name || ""));

				this.setState({
					defaultVans,
					order,
					...order
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});

				dispatch(push("/orders"));
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});

			dispatch(push("/orders"));
		}

		dispatch(setLoader(false));
	}

	async saveRoute() {
		const { orderFlow } = this.state;
		const { dispatch } = this.props;
		const { route } = orderFlow || {};
		const { gradeId, van } = route || {};

		if (!route) return;

		if (!gradeId?.trim()) {
			return notification.warn({
				message: Strings.orders.routes,
				description: Strings.orders.gradeIdMandatory,
				placement: "bottomRight",
				duration: 5
			});
		}

		if (!van) {
			return notification.warn({
				message: Strings.orders.routes,
				description: Strings.orders.vanMandatory,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		let response: any;
		try {
			const body = {
				gradeId,
				van
			};

			response = await API.put({
				url: Endpoints.uriRoutes(route?._id),
				data: body
			});

			if (response.ok) {
				this.setState({ hasUnsavedFields: false });
				dispatch(push("/orders"));

				notification.success({
					message: Strings.orders.routes,
					description: response?.data?.message || Strings.orders.routeEdited,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.orders.routes,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.orders,
							route: "/orders",
							icon: "order"
						},
						{
							text: Strings.orders.routes,
							icon: "route"
						},
						{
							text: this.state.orderFlow?.route?.name,
							icon: "route"
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () => this.saveRoute(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true
						}
					]
				};
			})
		);
	}

	renderRouteInfo() {
		const { orderFlow, defaultVans } = this.state;
		const { route } = orderFlow || {};

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{Strings.orders.routeInfo}</h2>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={8}>
						<label htmlFor="route_name" className="InputLabel">
							{Strings.orders.routeName}
						</label>
						<Input id="route_name" className="OrderInputReadOnly" value={route?.name || "-"} readOnly />
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="route_date" className="InputLabel">
							{Strings.orders.routeDate}
						</label>
						<Input
							id="route_date"
							className="OrderInputReadOnly"
							value={route?.date ? DateTime.fromISO(route.date).toFormat("dd-MM-yyyy HH:mm") : "-"}
							readOnly
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="route_id_grade" className="InputLabel">
							{Strings.orders.gradeId}
						</label>
						<Input
							id="route_id_grade"
							value={route?.gradeId || ""}
							placeholder={Strings.orders.gradeId}
							onChange={(e: any) => {
								route.gradeId = e.target.value;

								this.setState({
									orderFlow,
									hasUnsavedFields: true
								});
							}}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="route_evalyze_id" className="InputLabel">
							{Strings.orders.evalyzeId}
						</label>
						<Input
							id="route_evalyze_id"
							className="OrderInputReadOnly"
							value={route?.evalyzeId || "-"}
							readOnly
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="route_vehicle" className="InputLabel">
							{Strings.settings.vehiclePlate}
						</label>
						<Select
							style={{ width: "100%" }}
							placeholder={Strings.settings.vehiclePlate}
							showSearch
							showArrow
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={route?.van}
							onChange={(elem: any) => {
								route.van = elem;

								this.setState({
									orderFlow,
									hasUnsavedFields: true
								});
							}}
						>
							{defaultVans.map((van: any) => (
								<Select.Option key={`van_${van._id}`} value={van._id}>
									{van.licensePlate}
								</Select.Option>
							))}
						</Select>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderRouteBoxes() {
		const { orderFlow } = this.state;
		const { boxes = [] } = orderFlow || {};

		return (
			<Table
				title={{
					icon: "order",
					title: Strings.orders.routeBoxes
				}}
				data={boxes}
				columns={[
					{
						Header: Strings.orders.boxId,
						id: "boxId",
						accessor: (entry: any) => entry.id || "-"
					},
					{
						Header: Strings.orders.weight,
						id: "weight",
						accessor: (entry: any) => (entry.weight != null ? `${entry.weight.toFixed(3)} Kg` : "-")
					},
					{
						Header: Strings.orders.position,
						id: "position",
						accessor: (entry: any) => entry.position || "-"
					},
					{
						Header: Strings.orders.loadingOrder,
						id: "loadingOrder",
						accessor: (entry: any) => entry.loadingOrder || "-"
					}
				]}
				pageSize={27}
			/>
		);
	}

	render() {
		const { orderFlow } = this.state;
		const { route } = orderFlow || {};

		return (
			<div className="ScreenOrderRoute">
				<Helmet>
					<title>
						{Strings.orders.routes}
						{route?.name ? ` - ${route.name}` : ""}
					</title>
					<meta name="description" content="View and edit order route" />
				</Helmet>
				{this.renderRouteInfo()}
				{this.renderRouteBoxes()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.language
});

export default connect(mapStateToProps)(OrderRoute);
